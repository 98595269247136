import { ArrowUpIcon } from '@synoptic/ui-kit/icons/react/arrow-up.js';
import { RefObject, useImperativeHandle, useRef, useState } from 'react';
import {
  ItemContent,
  Virtuoso,
  VirtuosoHandle,
  VirtuosoProps,
} from 'react-virtuoso';
import { useScrollParent } from '../scroll-parent-context';
import { FeedFloatingAction } from './feed-floating-action';
import { useRestoreFeed } from './restore-feed/use-restore-feed';
import { Location } from 'react-router';
import { useComposedRefs } from '../../../../packages/ui-kit/src/utils/use-composed-refs';

type Prepend = (newValue: number | ((prev: number) => number)) => void;

export type onRestoreFeed = (args: {
  prepend: Prepend;
  setOutdated: (v: boolean) => void;
  fetchOptions: Partial<Request>;
}) => void;

export type FeedHandle = VirtuosoHandle & {
  prepend: Prepend;
};

export type FeedProps<Data, Context> = Omit<
  VirtuosoProps<Data, Context>,
  'customScrollParent'
> & {
  itemContent: ItemContent<Data, Context>;
  feedRef?: RefObject<FeedHandle | null>;
  gap?: number;

  getStorageKey?: (location: Location) => string;
  onRestore?: onRestoreFeed;
  onReset?: (args: { setOutdated: (v: boolean) => void }) => void;
  getBackToTopLabel?: (prependedItems: number) => string;
};

const defaultBackToTopLabel = (n: number) => `${n} New items`;

export const Feed = <Data, Context>({
  feedRef,
  gap,
  itemContent,
  increaseViewportBy = 800,
  totalListHeightChanged,
  getBackToTopLabel = defaultBackToTopLabel,
  atTopStateChange,
  getStorageKey,
  onRestore,
  onReset,
  ...props
}: FeedProps<Data, Context>) => {
  const ref = useRef<VirtuosoHandle>(null);
  const { scrollParent } = useScrollParent();

  const scrollLockRef = useRef(false);
  const [prependedItems, setPrependedItems] = useState(0);

  const prepend: Prepend = (v) => {
    scrollLockRef.current = true;

    setPrependedItems(v);
  };

  const { restoreStateFrom, style, virtuosoRef } = useRestoreFeed({
    getKey: getStorageKey,
    onRestore: ({ fetchOptions }) =>
      onRestore?.({ prepend, setOutdated, fetchOptions }),
  });

  const [outdated, setOutdated] = useState(false);

  const isWindowScroll = scrollParent === document.scrollingElement;

  const listHeight = useRef(0);

  useImperativeHandle(feedRef, () => ({
    prepend,
    ...ref.current!,
  }));

  const composedRef = useComposedRefs(ref, virtuosoRef);

  const itemContentWithGap: ItemContent<Data, Context> = gap
    ? (idx, data, context) => (
        <div style={idx > 0 ? { paddingTop: gap } : undefined}>
          {itemContent?.(idx, data, context)}
        </div>
      )
    : itemContent;

  return (
    <>
      <Virtuoso
        ref={composedRef}
        {...props}
        style={{ ...style, ...props.style }}
        restoreStateFrom={restoreStateFrom}
        atTopStateChange={(atTop) => {
          if (atTop) {
            setPrependedItems(0);
          }

          atTopStateChange?.(atTop);
        }}
        useWindowScroll={isWindowScroll}
        itemContent={itemContentWithGap}
        totalListHeightChanged={(height) => {
          // hack to keep scroll position on same item after prepend
          if (scrollParent) {
            if (scrollLockRef.current) {
              const delta = height - listHeight.current + Number(gap);

              scrollParent.scrollTop += delta;
            }
          }
          scrollLockRef.current = false;
          listHeight.current = height;
          totalListHeightChanged?.(height);
        }}
        customScrollParent={(!isWindowScroll && scrollParent) || undefined}
        increaseViewportBy={increaseViewportBy}
      />

      {outdated ? (
        <FeedFloatingAction
          onClick={() => {
            if (scrollParent) scrollParent.scrollTop = 0;
            onReset?.({ setOutdated });
          }}
          startIcon={<ArrowUpIcon />}
        >
          The feed is outdated. Click to refresh
        </FeedFloatingAction>
      ) : prependedItems > 0 ? (
        <FeedFloatingAction
          onClick={() => {
            if (scrollParent) scrollParent.scrollTop = 0;
          }}
          startIcon={<ArrowUpIcon />}
        >
          {getBackToTopLabel(prependedItems)}
        </FeedFloatingAction>
      ) : null}
    </>
  );
};
