import { createContext } from '@synoptic/ui-kit/utils/create-context.js';
import { noScroll } from './scroll-parent.css';

const [Provider, useCtx] = createContext<HTMLElement | null>(
  'ScrollParentProvider',
  null,
);

export const ScrollParentProvider = Provider;

// eslint-disable-next-line react-refresh/only-export-components
export const useScrollParent = () => {
  const scrollParent = useCtx() || (document.scrollingElement as HTMLElement);

  return {
    scrollParent,
    disableScroll() {
      scrollParent.classList.add(noScroll);
    },
    enableScroll() {
      scrollParent.classList.remove(noScroll);
    },
  };
};
