import { Button, ButtonProps } from '@synoptic/ui-kit/button/button.js';
import { forwardRef } from 'react';
import { feedFloatingAction } from './feed-floating-action.css';
import clsx from 'clsx';

export const FeedFloatingAction = forwardRef<HTMLButtonElement, ButtonProps>(
  function FeedFloatingAction({ className, ...props }, ref) {
    return (
      <Button
        ref={ref}
        className={clsx(feedFloatingAction, className)}
        rounded
        variant="secondary"
        size="small"
        {...props}
      />
    );
  },
);
